




















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { CurrentRoute } from '@/store/general/current-route/index';
@Component({})
export default class Sidebar extends Vue {
  $router!: VueRouter;
  returnLink: string = '';
  currentRoute = CurrentRoute.detail;

  mounted() {
    this.updateRouteName();
  }

  updateRouteName() {
    this.returnLink = this.currentRoute.returnLink;
  }
  isActiveFor(routeName: string) {
    const isDashboard = this.$router.currentRoute.matched.find((route) => {
      return route.name === routeName;
    });
    return Boolean(isDashboard);
  }
}
