import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/general';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'current-route.store',
  store,
})
class CurrentRouteStore extends VuexModule {
  returnLink: string = '';

  @Mutation
  setLinks(payload: any) {
    this.returnLink = payload;
  }

  @Action
  setReturnLinksState(params: string) {
    this.context.commit('setLinks', params);
  }
}

export default getModule(CurrentRouteStore);
