













































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Role } from '@/utils/constants';
import { Endpoints } from '@/store/poultry/endpoints';
import { axios } from '@/utils/axios';
import { ProfileStores } from '@/store/swine/profile/index';
import loginStore from '@/store/swine/login/login.store';
import { CurrentRoute } from '@/store/general/current-route/index';
@Component({})
export default class Navbar extends Vue {
  isOpen = false;
  showRequestCodeButton = true;
  showRequestCodeModal = false;
  profileStore = ProfileStores.profile;
  currentEmail = '';
  currentRouteStore = CurrentRoute.detail;

  @Prop({
    type: Boolean,
    default: true,
  })
  private showUserProfile!: boolean;

  mounted() {
    this.updatePermissions();
    this.updateRouterStore();
  }

  updatePermissions() {
    if (this.profileStore.userProfile) {
      const userProfile = this.profileStore.userProfile;
      this.showRequestCodeButton =
        userProfile.role === Role.Regional || userProfile.role === Role.Viewer
          ? false
          : true;
      this.currentEmail = userProfile.email;
    }
  }

  async signOut() {
    try {
      await axios.delete(Endpoints.Signout);
      this.currentRouteStore.setReturnLinksState('');
      loginStore.resetState();
      this.profileStore.resetState();
      this.$router.push({
        name: 'Login',
      });
    } catch (error) {
      console.error(error);
    }
  }

  updateRouterStore() {
    this.currentRouteStore.setReturnLinksState('PoultryDashboard');
  }
}
