















import { Component, Vue, Prop } from 'vue-property-decorator';
import Sidebar from '@/components/poultry/layout/Sidebar.vue';
import Navbar from '@/components/poultry/layout/Navbar.vue';

@Component({ components: { Sidebar, Navbar } })
export default class Page extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  private showUserProfile!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  private showSidebar!: boolean;

  @Prop({
    type: String,
    default: ''
  })
  private title!: string;
}
